/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-05 17:26:04 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-02-06 14:29:40
 */
<template>
  <div class="news">
    <div class="personal_top1">
      剩余普利点：<span class="c_F40707">{{
        user.ECash == null ? "0" : user.ECash
      }}</span>
    </div>
    <div class="cont">
      <el-table
        border
        :data="tableData"
        :header-cell-style="{ background: '#F3F3F3', color: '#666666' }"
      >
        <el-table-column align="center" min-width="25%" label="普利点">
          <template slot-scope="scope">
            <span class="c_F40707" v-if="scope.row.Category == 0"
              >-{{ scope.row.ECashMoney }}</span
            >
            <span class="c_28B28B" v-else>+{{ scope.row.ECashMoney }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" min-width="20%" label="类型">
          <template slot-scope="scope">
            <div>{{ scope.row.Category == 1 ? "充值" : "使用" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="30%"
          align="center"
          prop="CreateTime"
          label="操作时间"
        ></el-table-column>
        <el-table-column
          align="left"
          prop="Remark"
          label="使用说明"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { studentQueryStudentECashRecordList } from "@/api/personal";
export default {
  data() {
    return {
      tableData: [],
      listTopIndex: 1,
      page: 1, //页码
      pageSize: 5, //每页条数
      total: 0,
      user: {},
    };
  },
  created() {},
  mounted() {
    this.user = JSON.parse(this.$store.state.userInfo);
    this.init();
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.init();
    },
    async init() {
      let parm = {
        pageIndex: this.page,
        pageSize: this.pageSize,
      };
      const res = await studentQueryStudentECashRecordList(parm);
      if (res.success === true) {
        this.tableData = res.response.data;
        this.total = res.response.dataCount;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.news {
  .cont {
    padding: 30px;
    .el-button {
      color: #ffffff;
      border: none !important;
      &:hover {
        background: inheriti;
      }
    }
    /deep/.el-table {
      color: #333333;
      th {
        font-size: 18px;
      }
      td {
        font-size: 16px;
      }
    }
  }
  .personal_top {
    /deep/.el-badge {
      .el-badge__content.is-fixed {
        top: 25px;
      }
    }
  }
}
</style>